import React, { Component } from "react";
import ImageItem from "./image-item";
import TextItem from "./text-item";
import "./pairmatching.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Wrapper from "./wrapper";
import {
  SolveTheRiddle,
  GoToNextCourse,
  LastCourse,
  Competence
} from "../../shared/translations/translations";
import { NavLink } from "react-router-dom";

function shuffleArray(array) {
  let i = array.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

class PairMatching extends Component {
  state = {
    isValid: false,
    textList: []
  };
  onDragEnd = result => {
    let draggableId = result.draggableId;
    let destination = result.destination;
    if (destination) {
      let wrapper = document.querySelector(`#${destination.droppableId}`);
      let text = document.querySelector(`#${draggableId}`);
      if (wrapper && destination.droppableId) {
        wrapper.appendChild(text);
        if (destination.droppableId !== "texts") {
          let wrapperFor = wrapper.attributes.for.value;
          if (wrapperFor === draggableId) {
            text.setAttribute("aria-invalid", "false");
            text.style.color = "green";
          } else {
            text.setAttribute("aria-invalid", "true");
            text.style.color = "red";
          }
        } else {
          text.setAttribute("aria-invalid", "true");
          text.style.color = "";
        }
        this.checkIfAllValid();
      }
    }
  };
  checkIfAllValid() {
    let isValid = false;
    let textItems = document.querySelectorAll(".draggable__text");
    for (let i = 0; i < textItems.length; i++) {
      const item = textItems[i];
      let ariaInvalidValue = item.attributes["aria-invalid"].value;
      if (ariaInvalidValue === "false") {
        isValid = true;
      } else {
        isValid = false;
        break;
      }
    }
    this.setState({ isValid: isValid });
  }
  setTextsList() {
    let items = this.props.items;
    let list = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let text = { text: item.text, id: item.id };
      list.push(text);
    }
    return this.setState({ textList: shuffleArray(list) });
  }
  componentDidMount() {
    this.setTextsList();
  }

  getNextChapterId = () => {
    const sortedChapters = this.props.chapters.sort((x, y) => x-y);
    const currentChapterIndex = sortedChapters.findIndex(x => 
      x.id === parseInt(this.props.chapterId));

    const nextChapter = this.props.chapters[currentChapterIndex+1];
    return nextChapter.id;
  }

  render() {
    const { items, chapterId, chapters } = this.props;

    let chapter = chapters.find(x => x.id === parseInt(chapterId))
    let indexOfChapter = chapters.indexOf(chapter)

    let images = items.map((img, index) => (
      <Droppable
        droppableId={`imgWrapper-${img.id}`}
        index={index}
        key={img.id}
      >
        {provided => (
          <ImageItem
            id={img.id}
            url={img.url}
            desc={img.description}
            index={index}
            innerRef={provided.innerRef}
            provided={provided}
          />
        )}
      </Droppable>
    ));
    let texts = this.state.textList.map((text, index) => (
      <Draggable
        draggableId={`text-${text.id}`}
        index={index}
        key={`text-${text.id}`}
      >
        {provided => (
          <TextItem
            id={text.id}
            text={text.text}
            provided={provided}
            innerRef={provided.innerRef}
          />
        )}
      </Draggable>
    ));
    return (
      <section className="block">
        <div className="block__header">
          <h3 className="header__text">{SolveTheRiddle[this.props.lang]}</h3>
        </div>
        <div className="block__content pairmatching">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="block__images">{images}</div>
            <div className="block__texts droppable__wrapper">
              <Droppable droppableId="texts">
                {provided => (
                  <Wrapper
                    id="texts"
                    innerRef={provided.innerRef}
                    provided={provided}
                  >
                    {texts}
                    {provided.placeholder}
                  </Wrapper>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
        <div className="action__btns">
          {indexOfChapter + 2 > chapters.length && this.state.isValid && (
            <p className="action__text">{LastCourse[this.props.lang]}</p>
          )}
          <div>
            <NavLink
              to={`/${this.props.lang}/section/${this.props.sectionId}`}
              className="btn btn-secondary"
            >
              {Competence[this.props.lang]}
            </NavLink>
            {indexOfChapter + 2 <= chapters.length && (
              <a
                href={`/${this.props.lang}/section/${
                  this.props.sectionId
                }/chapter/${this.getNextChapterId()}`}
                className={`btn btn-primary ${
                  this.state.isValid ? "" : "disabled"
                }`}
                disabled={this.state.isValid ? "" : "disabled"}
              >
                {GoToNextCourse[this.props.lang]}
              </a>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default PairMatching;
