import React, { Component } from "react";
import "./scroll-up.scss";

class ScrollUp extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    let scrollUp = document.querySelector("#scrollUp");

    if (window.scrollY > 10) {
      scrollUp.classList.add("active");
    } else {
      scrollUp.classList.remove("active");
    }
  };
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <button
        className={`btn btn-primary scrollUp`}
        id="scrollUp"
        onClick={this.scrollToTop}
      >
        <span className="scrollUp__btn">&#10132;</span>
      </button>
    );
  }
}
export default ScrollUp;
