import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { origin } from "../../../config/config";
import './chapter.scss'
import { More } from "../translations/translations";

class Chapter extends Component {
  render() {
    const { imgSrc, title, lang, id, sectionId } = this.props;
    return (
      <div className="chapter">
        <div className="icon__wrapper">
          <img src={`${origin}${imgSrc}`} alt={title} className="icon"></img>
        </div>
        <div className="chapter__content">
          <h3 className="chapter__title">{title}</h3>
          <div className="chapter__btn">
            <NavLink to={`/${lang}/section/${sectionId}/chapter/${id}`} className="btn btn-primary">
              {More[lang]}
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
export default Chapter;
