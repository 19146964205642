import React, { Component } from "react";
import SelectLanguages from "../shared/language/select-language/select-language";
import Hero from "../shared/hero/hero";
import { getSections } from "./../shared/sections/sections-action";
import { getLanguages } from "./../shared/language/language-action";
import { connect } from "react-redux";
import Sections from "../shared/sections/sections";
import { NavLink } from "react-router-dom";
import { HomePage } from "../shared/translations/translations";
import "./section-page.scss";
import Accessibility from "../shared/accessibility/accessibility";

class SectionPage extends Component {
  state = {
    isFetched: false
  };
  tryFetch(props) {
    if (!this.state.isFetched) {
      let lang = this.props.match.params.lang;
      props.getSections(lang);
      this.setState({ isFetched: true });
    }
  }
  componentDidMount() {
    this.tryFetch(this.props);
  }
  componentDidUpdate(props) {
    this.tryFetch(props);
  }
  render() {
    let sections = this.props.sections.sections;
    let sectionId = this.props.match.params.section_id;
    let currentSection =
      sections.length !== 0
        ? sections.find(x => x.id === parseInt(sectionId))
        : {};
    return (
      <>
        <div className="header">
          <div className="container">
            <Accessibility lang={this.props.match.params.lang} />
            <SelectLanguages
              getLanguages={this.props.getLanguages}
              languages={this.props.languages}
              page={"section"}
              params={this.props.match.params}
            />
          </div>
        </div>
        <Hero />
        <div className="container">
          <div className="action__btn">
            <NavLink
              to={`/${this.props.match.params.lang}/main`}
              className="btn btn-primary"
            >
              <span className="btn__icon">&#10094;</span>
              {HomePage[this.props.match.params.lang]}
            </NavLink>
          </div>
        </div>
        {sections.length !== 0 && (
          <Sections
            id={currentSection.id}
            title={currentSection.name}
            chapters={currentSection.subSection}
            viewThree={false}
            lang={this.props.match.params.lang}
          />
        )}
      </>
    );
  }
}
const actions = {
  getLanguages,
  getSections
};
const mapStateToProps = state => {
  return {
    languages: state.languages,
    sections: state.sections
  };
};
export default connect(mapStateToProps, actions)(SectionPage);
