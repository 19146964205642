import React, { Component } from "react";
import { origin } from "../../../config/config";

class ImageItem extends Component {
  render() {
    const { id, url, desc, provided, innerRef } = this.props;
    return (
      <div className="droppable__wrapper" ref={innerRef}>
        <img src={`${origin}${url}`} alt={desc} className="droppable__img" />
        <div
          htmlFor={`text-${id}`}
          id={`imgWrapper-${id}`}
          className="droppable__placeholder"
        ></div>
        {provided.placeholder}
      </div>
    );
  }
}
export default ImageItem;
