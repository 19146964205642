import React, { Component } from "react";
import erasmus from "./../shared/images/erasmus_cofinance.png";
import trainInc from "./../shared/images/train_inc.png";
import { FounderMessage } from "../../components/shared/translations/translations";
import "./footer.scss";
import CookieConsent from "react-cookie-consent";

class Footer extends Component {
  render() {
    let lang = window.document.documentElement.lang;
    if (lang === "" || lang === "null") {
      lang = "en";
    }

    return (
      <footer className="footer">
        <div className="container">
          <div className="images__wrapper">
            <img
              src={erasmus}
              alt="erasmus"
              className="img-fluid footer__img"
            />
            <img
              src={trainInc}
              alt="train inc"
              className="img-fluid footer__img large"
            />
          </div>
          <div className="links__wrapper">
            <a
              href="http://amicos.org/train/"
              className="footer__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://amicos.org/train/
            </a>
          </div>
          <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
            {FounderMessage[lang]}
          </p>
        </div>
        <CookieConsent>
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
      </footer>
    );
  }
}
export default Footer;
