import React, { Component } from "react";
import { connect } from "react-redux";
import { getSections } from "../shared/sections/sections-action";
import { getLanguages } from "./../shared/language/language-action";
import Hero from "../shared/hero/hero";
import Sections from "../shared/sections/sections";
import SelectLanguages from "../shared/language/select-language/select-language";
import Accessibility from "../shared/accessibility/accessibility";

class MainPage extends Component {
  state = {
    isFetched: false
  };
  tryFetch(props) {
    if (!this.state.isFetched) {
      let lang = this.props.match.params.lang;
      props.getSections(lang);
      this.setState({ isFetched: true });
    }
  }
  componentDidMount() {
    this.tryFetch(this.props);
  }
  componentDidUpdate(props) {
    this.tryFetch(props);
  }
  render() {
    let sections = this.props.sections.sections;
    let sectionItems = sections.map(section => (
      <Sections
        key={section.id}
        id={section.id}
        title={section.name}
        chapters={section.subSection}
        viewThree={true}
        lang={this.props.match.params.lang}
      />
    ));
    return (
      <>
        <div className="header">
          <div className="container">
            <Accessibility lang={this.props.match.params.lang} />
            <SelectLanguages
              getLanguages={this.props.getLanguages}
              languages={this.props.languages}
              page={"main"}
              params={this.props.match.params}
            />
          </div>
        </div>
        <Hero />
        {sectionItems}
      </>
    );
  }
}
const actions = {
  getSections,
  getLanguages
};
const mapStateToProps = state => {
  return {
    sections: state.sections,
    languages: state.languages
  };
};
export default connect(mapStateToProps, actions)(MainPage);
