import { GET_LANGUAGES, SET_LANGUAGE } from "./language-action"


const initialState ={
    languages: [],
    currentLang: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_LANGUAGES: {
            return {
                ...state,
                languages: action.languages
            }
        }
        case SET_LANGUAGE: {
            return {
                ...state,
                currentLang: action.currentLang
            }
        }
        default:
            return state
    }
}

export default reducer;