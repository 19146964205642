import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Chapter from "../chapter/chapter";
import "./sections.scss";
import {Helmet} from "react-helmet";
import { MoreCompetence } from "../translations/translations";

class Sections extends Component {
  render() {
    const { title, chapters, lang, id } = this.props;
    let size = this.props.viewThree ? 3 : chapters.length;
    let chapterItems = chapters
      .slice(0, size)
      .map(chapter => (
        <Chapter
          key={chapter.id}
          id={chapter.id}
          imgSrc={chapter.iconUrl}
          title={chapter.name}
          lang={lang}
          sectionId={id}
        />
      ));
    return (
      <section className="section">
        {!this.props.viewThree && (
          <Helmet>
            <title>{`Train-inc - ${title}`}</title>
          </Helmet>
        )}
        {this.props.viewThree && (
          <Helmet>
            <title>{`Train-inc`}</title>
          </Helmet>
        )}
        <div className="container">
          <div className="section__header">
            <h2 className="header__text">{title}</h2>
          </div>
          <div className="chapters__wrapper">{chapterItems}</div>
          {this.props.viewThree && (
            <div className="action__btns">
              <NavLink
                to={`/${lang}/section/${id}`}
                className="section__btn btn btn-primary"
              >
                {MoreCompetence[lang]}
              </NavLink>
            </div>
          )}
        </div>
      </section>
    );
  }
}
export default Sections;
