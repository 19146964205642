export class Url {
  static home = "/";
  static main = "/:lang/main";
  static section = "/:lang/section/:section_id";
  static chapter = "/:lang/section/:section_id/chapter/:chapter_id"
}

export const origin = "https://api.train-inc.online/";

export class ApiConfig {
  static languages = `${origin}api/getLanguages`;
  static sections = `${origin}api/getSections`;
  static chapter = `${origin}api/v2/chapter`;
}
