import { handleError } from "../lib/helpers/errorHandler";
import Axios from "axios";
import { ApiConfig } from "../../config/config";

export const GET_CHAPTER = 'GET_CHAPTER'

export function getChapterAction(data) {
    return {
        type: GET_CHAPTER,
        chapter: data
    }
}

export function getChapter(id, lang) {
    return dispatch => {
      return new Promise(resolve => {
        Axios.get(`${ApiConfig.chapter}?id=${id}&countryCode=${lang}`)
          .then(response => {
            dispatch(getChapterAction(response.data));
            resolve();
          })
          .catch(error => {
            handleError(error);
            resolve();
          });
      });
    };
  }