import { combineReducers } from 'redux'

import languages from '../components/shared/language/language-reducers'
import sections from '../components/shared/sections/sections-reducers'
import chapter from '../components/chapter-page/chapter-reducers'

export default combineReducers (
    {
        languages,
        sections,
        chapter
    }
)