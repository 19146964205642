import React, { Component } from "react";
import parse from "html-react-parser";
import "./chapter-block.scss";

class ChapterBlock extends Component {
  render() {
    const { title, content } = this.props;
    return (
      <section className="block">
        <div className="block__header">
          <h3 className="header__text">{title}</h3>
        </div>
        <div className="block__content">{parse(content)}</div>
      </section>
    );
  }
}
export default ChapterBlock;
