import { ApiConfig } from "../../../config/config";
import Axios from "axios";
import { handleError } from "../../lib/helpers/errorHandler";

export const GET_SECTIONS = "GET_SECTIONS";

export function getSectionsAction(data) {
  return {
    type: GET_SECTIONS,
    sections: data
  };
}
export function getSections(lang) {
  return dispatch => {
    return new Promise(resolve => {
      Axios.get(`${ApiConfig.sections}?countryCode=${lang}`)
        .then(response => {
          dispatch(getSectionsAction(response.data));
          resolve();
        })
        .catch(error => {
          handleError(error);
          resolve();
        });
    });
  };
}
