import React, { Component } from "react";
import blackYellow from "./../../images/black-yellow.png";
import defaultContrast from "./../../images/default_contrast.png";
import "./change-contrast.scss";
import './../../style/contrast.scss'
import {
  DefaultContrast,
  BlackYellowContrast
} from "../../translations/translations";
import { addCookie, getCookie } from "../../../lib/helpers/cookie";

class ChangeContrast extends Component {
  addContrast = () => {
    let bodyItem = document.querySelector('body')
    bodyItem.classList.add('contrast')
    addCookie('contrast', 'blackYellow', '365')
  }
  removeContrast = () => {
    let bodyItem = document.querySelector('body')
    bodyItem.classList.remove('contrast')
    addCookie('contrast', 'default', '365')
  }
  componentDidMount() {
    let contrast = getCookie('contrast') || 'default'
    if(contrast === 'default') {
      this.removeContrast()
    } else if(contrast === 'blackYellow') {
      this.addContrast()
    }
  }

  render() {
    const { lang } = this.props;
    return (
      <div className="changeContrast">
        <ul className="changeContrast__list">
          <li>
            <button
              className="btn changeContrast__btn"
              title={DefaultContrast[lang]}
              onClick={this.removeContrast}
            >
              <img
                src={defaultContrast}
                alt={DefaultContrast[lang]}
                className="btn__img"
              />
            </button>
          </li>
          <li>
            <button
              className="btn changeContrast__btn"
              title={BlackYellowContrast[lang]}
              onClick={this.addContrast}
            >
              <img
                src={blackYellow}
                alt={BlackYellowContrast[lang]}
                className="btn__img"
              />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
export default ChangeContrast;
