import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import WelcomePage from "./components/welcome-page/welcomepage";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/reducers";
import thunk from "redux-thunk";
import Footer from "./components/footer/footer";
import { Url } from "./config/config";
import MainPage from "./components/main-page/main-page";
import SectionPage from "./components/section-page/section-page";
import ChapterPage from "./components/chapter-page/chapter-page";
import { getCookie } from "./components/lib/helpers/cookie";
import ScrollUp from "./components/shared/scroll-up/scroll-up";
import ScrollToTop from "./components/shared/scroll-to-top-router/scroll-to-top";

let store = createStore(reducers, applyMiddleware(thunk));
let lang = getCookie('lang')
window.document.documentElement.lang = lang
export class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <main className="main">
            <Router>
              <ScrollToTop>
                <Switch>
                  <Route exact path={Url.home} component={WelcomePage} />
                  <Route exact path={Url.main} component={MainPage} />
                  <Route exact path={Url.section} component={SectionPage} />
                  <Route exact path={Url.chapter} component={ChapterPage} />
                </Switch>
              </ScrollToTop>
            </Router>
        </main>
        <Footer />
        <ScrollUp />
      </Provider>

    );
  }
}

export default App;
