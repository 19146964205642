import React, { Component } from 'react';
import './hero.scss'

class Hero extends Component {
    render() {
        return(
            <div className={`hero ${this.props.bigHeroClass === undefined ? '' : this.props.bigHeroClass}`}>
                <div className="container">
                    <h1 className="hero__header">Train-inc</h1>
                </div>
            </div>
        )
    }
}
export default Hero;
