import React, { Component } from "react";

class Wrapper extends Component {
  render() {
    const { id, children, innerRef, provided } = this.props;
    return (
      <div
        className="droppable__placeholder"
        ref={innerRef}
        {...provided.droppableProps}
        id={id}
      >
        {children}
      </div>
    );
  }
}
export default Wrapper;
