import React, { Component } from "react";
import fontDefault from "./../../images/fontDefault.png";
import fontBigger from "./../../images/fontBigger.png";
import fontBig from "./../../images/fontBig.png";
import "./change-font-size.scss";
import { addCookie, getCookie } from "../../../lib/helpers/cookie";
import { DefaultFont, MediumFont, LargeFont } from "../../translations/translations";

const FontSize = {
  default: "1rem",
  bigger: "1.125rem",
  big: "1.25rem"
};
const MobileFontSize = {
  default: "0.875rem",
  bigger: "0.9375rem",
  big: "1rem"
};

class ChangeFontSize extends Component {
  changeFontSize = sizeName => {
    this.setFontSize(sizeName)
    addCookie("fontSize", sizeName, "365");
  };
  setFontSize = sizeName => {
    let htmlElement = document.querySelector("html");
    let windowWidth = window.innerWidth;
    htmlElement.style.fontSize =
      windowWidth > 768 ? FontSize[sizeName] : MobileFontSize[sizeName];
  };
  componentDidMount() {
    let sizeName = getCookie("fontSize") || "default";
    this.setFontSize(sizeName);
  }
  render() {
    const {lang} = this.props
    return (
      <div className="changeFontSize">
        <ul className="changeFontSize__list">
          <li>
            <button
              className="btn changeFontSize__btn"
              title={DefaultFont[lang]}
              onClick={this.changeFontSize.bind(this, "default")}
            >
              <img
                src={fontDefault}
                alt={DefaultFont[lang]}
                className="btn__img"
              />
            </button>
          </li>
          <li>
            <button
              className="btn changeFontSize__btn"
              title={MediumFont[lang]}
              onClick={this.changeFontSize.bind(this, "bigger")}
            >
              <img
                src={fontBigger}
                alt={MediumFont[lang]}
                className="btn__img"
              />
            </button>
          </li>
          <li>
            <button
              className="btn changeFontSize__btn"
              title={LargeFont[lang]}
              onClick={this.changeFontSize.bind(this, "big")}
            >
              <img src={fontBig} alt={LargeFont[lang]} className="btn__img" />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
export default ChangeFontSize;
