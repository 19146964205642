import { GET_SECTIONS } from './sections-action'

const initialState = {
    sections: []
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_SECTIONS: {
            return {
                ...state,
                sections: action.sections
            }
        }
        default:
            return state
    }
}
export default reducer;