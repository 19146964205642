import { ApiConfig } from "../../../config/config"
import { handleError } from "../../lib/helpers/errorHandler"
import axios from 'axios'

export const GET_LANGUAGES = 'GET_LANGUAGES'
export const SET_LANGUAGE = 'SET_LANGUAGE'



export function getLanguagesAction (data) {
    return {
        type: GET_LANGUAGES,
        languages: data
    }
}
export function setLanguage (lang) {
    return {
        type: SET_LANGUAGE,
        currentLang: lang
    }
}

export function getLanguages() {
    return dispatch => {
        return new Promise(resolve => {
            axios
            .get(ApiConfig.languages)
            .then(response => {
                dispatch(getLanguagesAction(response.data))
                resolve()
            })
            .catch(error => {
                handleError(error)
                resolve()
            })
        }) 
    }
}