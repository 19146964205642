import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreators from "./../../shared/language/language-action";
import LanguageItem from "./language-item";
import './language.scss'

export class Languages extends Component {
  state = {
    languages: [],
    isFetched: false
  };
  tryFetch(props) {
    if (!this.state.isFetched) {
      props.getLanguages();
      this.setState({ isFetched: true });
    }
  }
  componentDidMount() {
    this.tryFetch(this.props);
  }
  componentDidUpdate(props) {
    this.tryFetch(props);
  }

  render() {
    let languages = this.props.languages;
    let langItems = languages.map(lang => (
      <LanguageItem
        key={lang.lang}
        lang={lang.lang}
        imgAlt={lang.displayName}
        text={lang.displayName}
        setLanguage={this.props.setLanguage}
      />
    ));
    return (
      <section className="languages">
        <div className="container">
          <div className="languages__wrapper">{langItems}</div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = state => {
  return state.languages;
};
export default connect(mapStateToProps, actionCreators)(Languages);
