import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "./select-language.scss";
import { ChooseLanguage } from "../../translations/translations";
import { addCookie } from "../../../lib/helpers/cookie";

export class SelectLanguages extends Component {
  state = {
    languages: [],
    isFetched: false
  };
  tryFetch(props) {
    if (!this.state.isFetched) {
      props.getLanguages();
      this.setState({ isFetched: true });
    }
  }
  componentDidMount() {
    this.tryFetch(this.props);
  }
  componentDidUpdate(props) {
    this.tryFetch(props);
  }
  changeLanguage = event => {
    let value = event.target.value;
    addCookie('lang', value, 365);
    let sectionId = this.props.params.section_id;
    let chapterId = this.props.params.chapter_id;
    if (this.props.page === "main") {
      window.history.pushState({ lang: value }, "", `/${value}/main`);
    } else if (this.props.page === "section") {
      window.history.pushState(
        { lang: value },
        "",
        `/${value}/section/${sectionId}`
      );
    } else if (this.props.page === "chapter") {
      window.history.pushState(
        { lang: value },
        "",
        `/${value}/section/${sectionId}/chapter/${chapterId}`
      );
    }
    window.location.reload();
  };

  render() {
    let languages = this.props.languages.languages;
    let langItems = languages.map(lang => (
      <option key={lang.lang} value={lang.lang}>
        {lang.displayName}
      </option>
    ));

    return (
      <div className="selectLanguages__wrapper">
        <label htmlFor="lang" className="sr-only">
          {ChooseLanguage[this.props.params.lang]}
        </label>
        <Form.Control
          as="select"
          onChange={this.changeLanguage}
          value={this.props.params.lang}
          id="lang"
        >
          {langItems}
        </Form.Control>
      </div>
    );
  }
}

export default SelectLanguages;
