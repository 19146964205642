import React, { Component } from 'react';
import Languages from './languages/languages';
import Hero from './../shared/hero/hero'

class WelcomePage extends Component {
    render() {
        return (
            <>
                <Hero bigHeroClass={'big-hero'}/>
                <Languages />
            </>
        )
    }
}

export default WelcomePage;