import React, { Component } from "react";
import { getLanguages } from "./../shared/language/language-action";
import { getChapter } from "./chapter-actions";
import { getSections } from "./../shared/sections/sections-action";
import { connect } from "react-redux";
import SelectLanguages from "../shared/language/select-language/select-language";
import Hero from "../shared/hero/hero";
import { NavLink } from "react-router-dom";
import { Competence } from "../shared/translations/translations";
import ChapterBlock from "./chapter-block/chapter-block";
import "./chapter-page.scss";
import PairMatching from "./chapter-pairmatching/pair-matching";
import Accessibility from "../shared/accessibility/accessibility";
import { Helmet } from "react-helmet";
import { MoonLoader } from "react-spinners";

class ChapterPage extends Component {
  state = {
    isFetched: false,
    isContentLoaded: false,
  };

  tryFetch(props) {
    if (!this.state.isFetched) {
      let lang = this.props.match.params.lang;
      props
        .getChapter(props.match.params.chapter_id, lang)
        .then((x) => this.setState({ isContentLoaded: true }));

      props.getSections(lang);
      this.setState({ isFetched: true });
    }
  }

  componentDidMount() {
    this.tryFetch(this.props);
  }

  componentDidUpdate(props) {
    this.tryFetch(props);
  }

  render() {
    let sectionId = this.props.match.params.section_id;
    let currentSection = [];
    if (this.props.sections.length !== 0) {
      currentSection = this.props.sections.find(
        (x) => x.id === parseInt(sectionId)
      );
    }
    let blocks = this.props.chapter.blocks || [];
    let blocksContainer = [];
    if (blocks.length !== 0) {
      blocksContainer = blocks.map(
        (item) =>
          (item.type === "TitleRichText" && (
            <ChapterBlock
              key={item.id}
              id={item.id}
              title={item.value.title || item.value.defaultTitle}
              content={item.value.content || item.value.default}
              lang={this.props.match.params.lang}
            />
          )) ||
          (item.type === "PairMatching" && (
            <PairMatching
              key={item.id}
              items={item.value.pairImages}
              lang={this.props.match.params.lang}
              sectionId={sectionId}
              chapterId={this.props.match.params.chapter_id}
              chapters={currentSection.subSection || []}
            />
          ))
      );
    }

    return !this.state.isContentLoaded ? (
      <>
        <div style={{position:'absolute', margin: 0, top:'50%', left:'50%', marginRight:'-50%', transform:'translate(-50%, -50%)'}}>
          <MoonLoader size={80} loading={!this.state.isContentLoaded} />
        </div>
      </>
    ) : (
      <>
        <Helmet>
          <title>{`Train-inc - ${this.props.chapter.name}`}</title>
        </Helmet>
        <div className="header">
          <div className="container">
            <Accessibility lang={this.props.match.params.lang} />
            <SelectLanguages
              getLanguages={this.props.getLanguages}
              languages={this.props.languages}
              page={"chapter"}
              params={this.props.match.params}
            />
          </div>
        </div>
        <Hero />
        <div className="container">
          <div className="action__btn">
            <NavLink
              to={`/${this.props.match.params.lang}/section/${sectionId}`}
              onClick={() => {}}
              className="btn btn-primary"
            >
              <span className="btn__icon">&#10094;</span>
              {Competence[this.props.match.params.lang]}
            </NavLink>
          </div>
          <div className="chapter__header">
            <h2 className="header__text">{this.props.chapter.name}</h2>
          </div>
          <div className="chapter__blocks">{blocksContainer}</div>
        </div>
      </>
    );
  }
}
const actions = {
  getChapter,
  getLanguages,
  getSections,
};
const mapStateToProps = (state) => {
  return {
    chapter: state.chapter.chapter,
    languages: state.languages,
    sections: state.sections.sections,
  };
};
export default connect(mapStateToProps, actions)(ChapterPage);
