export class More {
  static en = "More";
  static rum = "Mai mult";
  static pt = "Mais";
  static es = "Más";
  static bg = "още";
  static pl = "Więcej";
}
export class MoreCompetence {
  static en = "More competence";
  static rum = "Mai multă competență";
  static pt = "Mais competência";
  static es = "Más competencia";
  static bg = "повече компетентност";
  static pl = "Więcej kompetencji";
}
export class HomePage {
  static en = "Home page";
  static rum = "Pagina principala";
  static pt = "Pagina inicial";
  static es = "Página de inicio";
  static bg = "Начална страница";
  static pl = "Strona główna";
}
export class GoToNextCourse {
  static en = "Go to the next course";
  static rum = "Mergeți la cursul următor";
  static pt = "Ir para o próximo curso";
  static es = "Ir al siguiente curso";
  static bg = "Преминете към следващия курс";
  static pl = "Przejdź do następnego kursu";
}
export class Competence {
  static en = "Competence";
  static rum = "Competență";
  static pt = "Competência";
  static es = "Competencia";
  static bg = "компетентност";
  static pl = "Kompetencje";
}
export class SolveTheRiddle {
  static en = "Solve the riddle";
  static rum = "Rezolva ghicitoarea";
  static pt = "Resolva o enigma";
  static es = "Resuelve el enigma";
  static bg = "Решете гатанката";
  static pl = "Rozwiąż zagadkę";
}
export class LastCourse {
  static en = "This is the last course. Congratulations.";
  static rum = "Acesta este ultimul curs. Felicitări.";
  static pt = "Este é o último curso. Parabéns.";
  static es = "Este es el último curso. Felicidades.";
  static bg = "Това е последният курс. Честито.";
  static pl = "To jest ostatni kurs. Gratulacje.";
}
export class ChooseLanguage {
  static en = "Choose language";
  static rum = "Alegeți limba";
  static pt = "Escolha o idioma";
  static es = "Elige lengua";
  static bg = "Изберете език";
  static pl = "Wybierz język";
}
export class DefaultFont {
  static en = "Default font";
  static rum = "Font implicit";
  static pt = "Fonte padrão";
  static es = "Fuente predeterminada";
  static bg = "Шрифт по подразбиране";
  static pl = "Czcionka domyślna";
}
export class MediumFont {
  static en = "Medium font";
  static rum = "Font mediu";
  static pt = "Fonte média";
  static es = "Fuente mediana";
  static bg = "Среден шрифт";
  static pl = "Czcionka średnia";
}
export class LargeFont {
  static en = "Large font";
  static rum = "Font mare";
  static pt = "Fonte grande";
  static es = "Letra grande";
  static bg = "Голям шрифт";
  static pl = "Czcionka duża";
}
export class BlackYellowContrast {
  static en = "Black and yellow contrast";
  static rum = "Contrast negru și galben";
  static pt = "Contraste preto e amarelo";
  static es = "Contraste negro y amarillo";
  static bg = "Черен и жълт контраст";
  static pl = "Kontrast czarno-żółty";
}
export class DefaultContrast {
  static en = "Default contrast";
  static rum = "Contrast implicit";
  static pt = "Contraste padrão";
  static es = "Contraste predeterminado";
  static bg = "Контраст по подразбиране";
  static pl = "Kontrast domyślny";
}

export class FounderMessage  {
  static en = "This project has been funded with support from the European Commission. This publication [communication] reflects the views only of the author, and the Commission cannot be held responsible for any use which may be made of the information contained therein.";
  static rum = "Acest proiect a fost finanţat cu sprijinul Comisiei Europene.<0} Această publicaţie (comunicare) reflectă numai punctul de vedere al autorului şi Comisia nu este responsabilă pentru eventuala utilizare a informaţiilor pe care le conţine.";
  static pt = "Projecto financiado com o apoio da Comissão Europeia. A informação contida nesta publicação (comunicação) vincula exclusivamente o autor, não sendo a Comissão responsável pela utilização que dela possa ser feita.";
  static es = "El presente proyecto ha sido financiado con el apoyo de la Comisión Europea. Esta publicación (comunicación) es responsabilidad exclusiva de su autor. La Comisión no es responsable del uso que pueda hacerse de la información aquí difundida.";
  static bg = "Този проект е финансиран с подкрепата на Европейската комисия. Тази публикация [съобщение] отразява само личните виждания на нейния автор и от Комисията не може да бъде търсена отговорност за използването на съдържащата се в нея информация.";
  static pl = "Ten projekt został zrealizowany przy wsparciu finansowym Komisji Europejskiej. Projekt lub publikacja odzwierciedlają jedynie stanowisko ich autora i Komisja Europejska nie ponosi odpowiedzialności za umieszczoną w nich zawartość merytoryczną.";
}
