import React, { Component } from "react";
import { Flags } from "../../shared/images/flags";
import { addCookie } from "../../lib/helpers/cookie";

class LanguageItem extends Component {
  setLanguage = (lang) => {
    this.props.setLanguage(lang);
    addCookie('lang', lang.lang, 365)
    window.history.pushState({'lang': lang.lang}, '', `/${lang.lang}/main`)
    window.location.reload()
  }
  render() {
    const { lang, imgAlt, text } = this.props;
    return (
      <button className="btn languages__item" onClick={() => this.setLanguage({ lang: lang, name: text })}>
        <img src={Flags[lang]} alt={imgAlt} className="item__img" />
        <p className="item__text">{text}</p>
      </button>
    );
  }
}
export default LanguageItem;
