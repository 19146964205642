export const addCookie = (name, key, expires) => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 24 * 60 * 60 * expires;
    now.setTime(expireTime);
    document.cookie = `${name}=${key ||
      "en-pl"}; expires='${now.toUTCString()}';path=/`;
  }
  export const getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
  