import en from './../images/flag-en.png'
import rum from './../images/flag-rum.png'
import pt from './../images/flag-pt.png'
import es from './../images/flag-es.png'
import bg from './../images/flag-bg.png'
import pl from './../images/flag-pl.png'


export class Flags {
    static en = en;
    static rum = rum;
    static pt = pt;
    static es = es;
    static bg = bg;
    static pl = pl;
}