import React, { Component } from "react";
class TextItem extends Component {
  render() {
    const { id, text, provided, innerRef } = this.props;
    return (
      <div
        className="draggable__text"
        ref={innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        id={`text-${id}`}
        aria-invalid="true"
      >
        {text}
      </div>
    );
  }
}
export default TextItem;
