import { GET_CHAPTER } from './chapter-actions'

const initialState = {
    chapter: {}
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_CHAPTER: {
            return {
                ...state,
                chapter: action.chapter
            }
        }
        default:
            return state
    }
}
export default reducer;