import React, { Component } from "react";
import ChangeFontSize from "./change-font-size/change-font-size";
import './accessibility.scss'
import ChangeContrast from "./change-contrast/change-contrast";

class Accessibility extends Component {
    render() {
        return(
            <div className="accessibility">
                <ChangeContrast lang={this.props.lang} />
                <ChangeFontSize lang={this.props.lang} />
            </div>
        )
    }
}
export default Accessibility;